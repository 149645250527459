import React, { useState } from "react"
import {
  Lighthouse, ChessUp, TurnTable, BoostedDS, Littera, CueHit,
  LTWebPayments, LightHub, BoostedWeb, BoostedMobile, Vega,
  PetLine, RapidReceipt, Halligan, Snupps, SnuppsMarketplace,
  Tusk, Together, SwiftDine, Wait, Chess
} from "./homeProjects"
import {
  LighthouseStudy, CueHitStudy, SnuppsStudy, ChessUpStudy
} from "./homeStudies"
import ContactForm from "../../components/contactForm"
import Recommendations from "./homeRecommendations"

const ProjectsView = () => {
  const [isMoreShown, setIsMoreShown] = useState(false);

  const toggleIsMoreShown = () => {
    setIsMoreShown(!isMoreShown);
  };

  return (
    <article className="projectsList">
      <Lighthouse />
      <CueHit />
      <LTWebPayments />

      <div className="sideBySide">
        <BoostedMobile />
        <Vega />
      </div>

      <RapidReceipt />
      <Snupps />

      { isMoreShown ?
        <>
          <div className="sideBySide">
            <ChessUp />
            <TurnTable />
          </div>

          <Littera />

          <BoostedDS />
          <LightHub />
          <BoostedWeb />

          <PetLine />

          <Halligan />

          <div className="sideBySide">
            <SnuppsMarketplace />
            <Tusk />
          </div>

          <div className="sideBySide">
            <Together />
            <SwiftDine />
          </div>

          <div className="sideBySide">
            <Wait />
            <Chess />
          </div>
        </>
      :
        <button className="button large primary seeMore" onClick={toggleIsMoreShown}>
          <div className="buttonTitle">See all projects...</div>
        </button>
      }

      <Recommendations />

      <ContactForm />
    </article>
  )
}

function ContentProvider(props) {
  const { selection } = props
  switch(selection) {
    case 'item1':
      return (
        <ProjectsView />
      )
    case 'item2':
      return (
        <section className="caseStudiesList mercurySection">
          <div className="sectionContent">
            <LighthouseStudy />
            <CueHitStudy />
            <ChessUpStudy />
            <SnuppsStudy />
          </div>

          <ContactForm />
        </section>
        )
    default:
      return (
        <div>
          You shouldn't be seeing this error.
        </div>
      )
  }
}

export default function HomeContent(props) {
  const [tabSelection, setTab] = React.useState('item1')

  const handleItem1Change = () => {
    setTab('item1')
  }

  const handleItem2Change = () => {
    setTab('item2')
  }

  return (
    <>
      <section className="mercurySection contentTabsSection">
        <div className={`segmentedFloat ${ tabSelection }`}>
          <div className="floatSegments">
            <label className="floatSegment item1" htmlFor="item1">
              <input type="radio" checked={tabSelection === 'item1'} onChange={handleItem1Change} id="item1"/>
              <span className="segmentTitle">Work</span>
            </label>

            <label className="floatSegment item2" htmlFor="item2">
              <input type="radio" checked={tabSelection === 'item2'} onChange={handleItem2Change} id="item2"/>
              <span className="segmentTitle">Case Studies</span>
            </label>
          </div>

          <div className="floatSelection" />
        </div>
      </section>

      <ContentProvider selection={tabSelection} />
    </>
  )
}
