import React from "react"
import { AssetInputPopUpChevron } from "./assets"

export default function ContactForm(props) {
  return (
    <section className="hireBanner">
      <div className="bannerContainer">
        <div className="bannerHeader">
          <div className="bannerTitle">Keen to chat?</div>
          <p className="bannerSubtitle">Drop me a message below and I will get back to you as soon as I can! 🙌</p>
        </div>

        <div className="separator"></div>

        <div className="bannerContent">

          <div className="hireForm">
            <div className="formContent">
              <form name="contact" method="post" action="/success" data-netlify="true" data-netlify-honeypot="bot-field">
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />

{/*                <div className="formRow">
                  <label className="formInput selectInput">
                    <div className="formInputTitle">What best describes you?</div>

                    <div className="formInputValueContainer">
                      <select className="formInputValue" name="role[]">
                        <option value="none">Select...</option>
                        <option value="founder">Founder</option>
                        <option value="pm">Product Manager</option>
                        <option value="designer">Designer</option>
                        <option value="recruiter">Recruiter</option>
                        <option value="other">Other</option>
                      </select>
                      <AssetInputPopUpChevron className="popUpIcon" />
                    </div>
                  </label>

                  <label className="formInput selectInput">
                    <div className="formInputTitle">Role type</div>

                    <div className="formInputValueContainer">
                      <select className="formInputValue" name="type[]">
                        <option value="none">Select...</option>
                        <option value="full-time">Full-Time</option>
                        <option value="part-time">Part-Time</option>
                        <option value="contract">Contract</option>
                      </select>
                      <AssetInputPopUpChevron className="popUpIcon" />
                    </div>
                  </label>
                </div>

                <div className="formRow">
                  <label className="formInput selectInput">
                    <div className="formInputTitle">Compensation</div>

                    <div className="formInputValueContainer">
                      <select className="formInputValue" name="comp[]">
                        <option value="none">Select...</option>
                        <option value="75">£75,000 - £85,000</option>
                        <option value="86">£86,000 - £95,000</option>
                        <option value="100-plus">£100,000+</option>
                      </select>
                      <AssetInputPopUpChevron className="popUpIcon" />
                    </div>
                  </label>

                  <label className="formInput selectInput">
                    <div className="formInputTitle">Start date</div>

                    <div className="formInputValueContainer">
                      <select className="formInputValue" name="start-date[]">
                        <option value="none">Select...</option>
                        <option value="1-month">in 1 month</option>
                        <option value="2-months">in 2 months</option>
                        <option value="3-months">in 3 months</option>
                      </select>
                      <AssetInputPopUpChevron className="popUpIcon" />
                    </div>
                  </label>
                </div>*/}

                <div className="formRow">
                  <label className="formInput">
                    <div className="formInputTitle">Name</div>
                    <input className="formInputValue" type="text" name="name" placeholder="George Jones" />
                  </label>

                  <label className="formInput">
                    <div className="formInputTitle">Organization</div>
                    <input className="formInputValue" type="text" name="organization" placeholder="ACME Limited" />
                  </label>
                </div>

                <div className="formRow">
                  <label className="formInput">
                    <div className="formInputTitle">Email</div>
                    <input className="formInputValue" type="email" name="email" placeholder="george@acme.co.uk" required/>
                  </label>

                  <label className="formInput">
                    <div className="formInputTitle">Mobile (Optional)</div>
                    <input className="formInputValue" type="tel" name="number" placeholder="07432 123 456" />
                  </label>
                </div>

                <label className="formInput multilineInput">
                  <div className="formInputTitle">Message</div>
                  <textarea className="formInputValue" name="message" placeholder="Hi Phil, ..." required></textarea>
                </label>

                <div className="submitRow">
                  <button className="submitButton button large primary" type="submit">
                    <div className="buttonTitle">Send to Phil</div>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
