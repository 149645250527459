import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import CaseStudyPreviewItem from "../study/studyPreviewItem"

const LighthouseStudy = () => {
  return (
    <CaseStudyPreviewItem
      studyClass="projectLighthouse"
      link="/projects/lightricks-design-system"
      product="Lightricks Design System"
      company="Lightricks, Ltd."
      description="Designing a cross-organizational and cross-platform design system used across many Lightricks apps."
      role="Senior Product Designer"
      duration="2021-2022"
      icon={
        <StaticImage
          src="../../images/imageProjectPreviewIconLightricks.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A Lighthouse icon."
        />
      }
    />
  )
}

const ChessUpStudy = () => {
  return (
    <CaseStudyPreviewItem
      studyClass="projectChessUp"
      link="/projects/chessup"
      product="ChessUp"
      company="Freelance"
      description="Designing a beautiful mobile chess experience for a connected chessboard with a built-in chess instructor."
      role="Senior Product Designer"
      duration="2021"
      icon={
        <StaticImage
          src="../../images/imageProjectPreviewIconChessUp.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A ChessUp icon."
        />
      }
    />
  )
}

const CueHitStudy = () => {
  return (
    <CaseStudyPreviewItem
      studyClass="projectCueHit"
      link="/projects/cuehit"
      product="CueHit"
      company="Acquired by NEOGOV"
      description="Empowering 911, Law Enforcement and Fire/EMS to better communicate with citizens and provide a better service."
      role="Cofounder & Head of Design"
      duration="2019-2021"
      icon={
        <StaticImage
          src="../../images/imageProjectPreviewIconCueHit.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A CueHit app icon."
        />
      }
    />
  )
}

const SnuppsStudy = () => {
  return (
    <CaseStudyPreviewItem
      studyClass="projectSnupps"
      link="/projects/snupps"
      product="Snupps"
      company="Snupps, Inc."
      description="Enabling collectors to organize, buy & sell their stuff and connect with others who share their passion and interests."
      role="Lead Product Designer"
      duration="2013-2018"
      icon={
        <StaticImage
          src="../../images/imageProjectPreviewIconSnupps.png"
          placeholder="blurred"
          layout="constrained"
          quality={100}
          alt="A Snupps app icon."
        />
      }
    />
  )
}

export {
  LighthouseStudy, CueHitStudy, SnuppsStudy, ChessUpStudy
}
