import React from "react"
import { Link } from "gatsby"

export default function CaseStudyPreviewItem(props) {
  return (
    <Link to={props.link} className={`caseStudyItem ${ props.studyClass }`}>

      <article className="studyDetails">
        <div className="studyHeader">
          <div className="studyIcon">{props.icon}</div>
          
          <div className="studyHeaderText">
            <h2 className="studyProduct">{props.product}</h2>
            <p className="studyCompany">{props.company}</p>
          </div>
        </div>

        <div className="separator"/>

        <p className="studyDescription">{props.description}</p>

        <div className="separator"></div>

        <div className="studyRoleDetails">
          <h3 className="studyRole">{props.role}</h3>
          <p className="studyDuration">{props.duration}</p>
        </div>
      </article>

{/*      <Link to={props.link} className="button large secondary">
        <p className="buttonTitle">Read</p>
      </Link>*/}
    </Link>
  )
}
