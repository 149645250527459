import React from "react"
import { Link } from "gatsby"
import Seo from "../components/seo"
import "../styles/styles.sass"
import Header from "../components/header"
import Footer from "../components/footer"
import HomeIntro from "../views/home/homeIntro"
import HomeContent from "../views/home/homeContent"

const SelectedPage = () => {
  return (
    <main className="mercuryView homeView selectedView">
      <Seo
        title="Phil Amour — Hire a Senior Product Designer"
        image="/imageSEOHire.jpg"
      />
      
      <div className="headerSpacer" />

      <Header action="resume" />

      <div className="headerSpacer" />

      <div className="mercuryContent">
        <HomeIntro
          text={<>
            Hi there! 👋 I'm Phil - a passionate software designer who dabbles in front-end and obsesses over little details without missing the big picture. <br /> <br />

            I believe that transparency, good communication, collaboration and great design are vital in making great products that people love. 💙<br /> <br />

            I'm currently looking for a new challenge. Please take a look at a selection of my recent work or <Link to="/case-studies">view my case studies</Link> and don't hesitate to reach out if you'd like to work together! 🤩
          </>}
          portrait="navy"
        />

        <HomeContent />
      </div>
      
      <Footer />
    </main>
  )
}

export default SelectedPage
